import React from "react";
import { useValidatedField } from "../hooks/useValidatedField";
import { Fieldset } from "./Fieldset";
import { Field, FieldLabel, FieldInput, FieldError, PasswordFieldInput } from "./Field";

export interface FoursquareCredentials {
  readonly clientId: string;
  readonly clientSecret: string;
}

export interface FoursquareLoginProps {
  /**
   * Callback to invoke when credentials are updated.
   */
  readonly onChange: (credentials: FoursquareCredentials) => void;
}

/**
 * Fieldset with fields for Foursquare login credentials.
 */
export const FoursquareLogin = ({ onChange }: FoursquareLoginProps) => {
  const { value: clientId, setValue: setClientId, error: clientIdError } = useValidatedField({
    required: true
  });
  const { value: clientSecret, setValue: setClientSecret, error: clientSecretError } = useValidatedField({
    required: true
  });

  React.useEffect(() => {
    onChange({ clientId, clientSecret });
  }, [clientId, clientSecret, onChange]);

  return (
    <Fieldset header="Foursquare Login">
      <Field>
        <FieldLabel htmlFor="clientId">Client ID</FieldLabel>
        <FieldInput
          autoFocus
          hasError={!!clientIdError}
          id="clientId"
          name="clientId"
          onChange={event => setClientId(event.target.value)}
          type="text"
          value={clientId}
        />
        <FieldError error={clientIdError} />
      </Field>
      <Field>
        <FieldLabel htmlFor="clientSecret">Client Secret</FieldLabel>
        <PasswordFieldInput
          hasError={!!clientSecretError}
          id="clientSecret"
          name="clientSecret"
          onChange={event => setClientSecret(event.target.value)}
          value={clientSecret}
        />
        <FieldError error={clientSecretError} />
      </Field>
    </Fieldset>
  );
};
