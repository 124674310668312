import React from "react";
import { FoursquareCredentials } from "../components/FoursquareLogin";
import { Venue } from "../components/Venue";
import { useDebouncedValue } from "./useDebouncedValue";

/**
 * Data included in a successful load response.
 */
export interface VenueSearchSuccessResponse {
  response: {
    venues: Venue[];
  };
}

/**
 * Data included in a failed load response.
 */
export interface VenueSearchErrorResponse {
  meta: {
    code: number;
    errorDetail: string;
    errorType: "invalid_auth" | string;
  };
}

/**
 * Parses an API call response and applies updates via the provided callbacks.
 */
async function processVenueSearchResponse(
  response: Response,
  setError: (error: string) => void,
  setVenues: (venues: Venue[] | undefined) => void
) {
  const jsonResponse = await response.json();
  if (response.ok) {
    const successResponse: VenueSearchSuccessResponse = jsonResponse;
    setVenues(successResponse.response.venues);
  } else {
    const errorResponse: VenueSearchErrorResponse = jsonResponse;
    switch (errorResponse.meta.errorType) {
      // See https://developer.foursquare.com/docs/places-api/errors/
      // for a list of known error types; more could be added here to
      // customize error messages.
      case "invalid_auth": {
        setError("Invalid Client ID or Client Secret");
        break;
      }
      default: {
        setError(errorResponse.meta.errorDetail || "An unexpected error occurred loading venues");
        break;
      }
    }
  }
}

/**
 * Settings for the "useVenueSearch" hook.
 */
export interface UseVenueSearchSettings {
  readonly credentials: FoursquareCredentials | undefined;
  readonly debounceDelay?: number;
  readonly near: string;
  readonly searchPhrase: string | undefined;
}

/**
 * Hook that loads venues from Foursquare when search criteria are updated.
 */
export const useVenueSearch = ({ credentials, debounceDelay = 300, near, searchPhrase }: UseVenueSearchSettings) => {
  const debouncedSearchPhrase = useDebouncedValue(searchPhrase, debounceDelay);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [venues, setVenues] = React.useState<Venue[]>();

  // Clear previously-loaded venues if there is an error
  React.useEffect(() => {
    if (error) {
      setVenues(undefined);
    }
  }, [error]);

  // Clear venues if search phrase is cleared
  React.useEffect(() => {
    if (!searchPhrase) {
      setVenues(undefined);
    }
  }, [searchPhrase]);

  // Load venues when debounced search phrase is updated
  React.useEffect(() => {
    setError(undefined); // clear previous error (if set)

    // Skip loading if any of the required settings are missing
    if (!debouncedSearchPhrase || debouncedSearchPhrase.length < 3) {
      setVenues(undefined);
      return;
    }
    if (!credentials?.clientId || !credentials?.clientSecret) {
      setError("Missing Client ID or Client Secret");
      setVenues(undefined);
      return;
    }

    setLoading(true);
    fetch(
      // TODO: Extract URL to a config file?
      `https://api.foursquare.com/v2/venues/search?near=${near}&query=${debouncedSearchPhrase}&client_id=${credentials.clientId}&client_secret=${credentials.clientSecret}&v=20200226`
    )
      .then(response => processVenueSearchResponse(response, setError, setVenues))
      .catch(response => setError(response.message ?? "An unexpected error occurred"))
      .finally(() => setLoading(false));

    // "credentials" isn't included because we only want to perform
    // a search when the search phrase is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPhrase, near]);

  return { error, loading, venues };
};
