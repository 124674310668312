import cx from "classnames";
import React from "react";
import styles from "./Alert.module.css";

export type AlertType = "warning" | "info";

export interface AlertProps {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly dismissible?: boolean;
  readonly type?: AlertType;
}

/**
 * Generic alert for showing warnings or info messages.
 */
export const Alert = ({ children, className, dismissible = true, type = "info" }: AlertProps) => {
  const [visible, setVisible] = React.useState<boolean>(true);

  if (!visible) {
    return <></>;
  }

  let typeClass: string;
  switch (type) {
    case "info": {
      typeClass = styles.Alert_info;
      break;
    }
    case "warning": {
      typeClass = styles.Alert_warning;
    }
  }

  return (
    <div className={cx(styles.Alert, typeClass, className)}>
      <div className={styles.AlertContent}>{children}</div>
      {dismissible && (
        <button className={styles.AlertDismissButton} onClick={() => setVisible(false)}>
          ×
        </button>
      )}
    </div>
  );
};
