import cx from "classnames";
import React from "react";
import { AiFillWarning, AiOutlineCheckCircle } from "react-icons/ai";
import { useSimilarVenues } from "../hooks/useSimilarVenues";
import { Alert } from "./Alert";
import { D3Graph } from "./D3Graph";
import { FoursquareCredentials } from "./FoursquareLogin";
import { Venue } from "./Venue";
import styles from "./Graph.module.css";

/**
 * Maximum number of venues to show in the graph.
 *
 * This limit ensures that the graph doesn't grow exponentially and unintentionally
 * exceed the request quotas of Foursquare's API.
 */
const maxVenues = 40;

export interface GraphProps {
  readonly credentials: FoursquareCredentials | undefined;
  readonly seedVenue: Venue | undefined;
}

/**
 * Container element for the D3 graph.
 *
 * Includes the welcome message, the D3 graph, and the graph's status message.
 */
export const Graph = ({ credentials, seedVenue }: GraphProps) => {
  const { error, loading, similarVenues } = useSimilarVenues({ credentials, seedVenue, maxVenues });

  // The welcome message is only visible until the first graph is rendered;
  // a ref is used to track this state so that it is stable across renders
  const showWelcome = React.useRef(true);

  // Automatically clear the welcome message on first search
  React.useEffect(() => {
    if (seedVenue && showWelcome.current) {
      showWelcome.current = false;
    }
  }, [seedVenue]);

  let statusMessage;
  let statusIcon;
  const venuesCount = similarVenues?.venues.length || 0;
  if (error) {
    statusMessage = error;
    statusIcon = <AiFillWarning className={styles.GraphErrorIcon} size={20} />;
  } else if (loading) {
    statusMessage = "Loading graph. Press the spacebar to stop.";
    statusIcon = <div className={styles.GraphLoadingIcon} />;
  } else if (venuesCount === 1) {
    statusMessage = "Loading complete. No similar venues found.";
  } else if (venuesCount >= maxVenues) {
    statusMessage = "Loading complete. Maximum graph size reached.";
  } else if (venuesCount > 0) {
    statusMessage = "Loading complete. Hover over nodes to view details.";
    statusIcon = <AiOutlineCheckCircle className={styles.GraphCheckmarkIcon} size={20} area-hidden />;
  }

  return (
    <div className={styles.Graph}>
      <D3Graph similarVenues={similarVenues} />
      {showWelcome.current && !seedVenue && (
        <Alert className={styles.WelcomeAlert}>
          <strong>Welcome!</strong>
          <p className={styles.WelcomeAlertParagraph}>
            To start, enter your{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Foursquare"
              href="https://foursquare.com/developers/signup"
            >
              Foursquare
            </a>{" "}
            credentials. Then search for a venue and interact with the graph to find related venues.
          </p>
        </Alert>
      )}
      {(statusMessage || statusIcon) && (
        <div className={cx(styles.GraphStatus, { [styles.GraphStatus_error]: error })}>
          <span className={styles.GraphStatusMessage}>{statusMessage}</span>
          {statusIcon}
        </div>
      )}
    </div>
  );
};
