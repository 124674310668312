import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "./Header.module.css";

export const Header = () => (
  <header className={styles.Header}>
    <Logo className={styles.RavelinLogo} aria-hidden />
    <h1 className={styles.HeaderTitle}>Ravelin Code Test</h1>
  </header>
);
