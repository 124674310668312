import cx from "classnames";
import React from "react";
import { useValidatedField } from "../hooks/useValidatedField";
import { useVenueSearch } from "../hooks/useVenueSearch";
import { Alert } from "./Alert";
import { Fieldset } from "./Fieldset";
import { Field, FieldLabel, FieldError, ProgressFieldInput } from "./Field";
import { FoursquareCredentials } from "./FoursquareLogin";
import { Venue } from "./Venue";
import styles from "./VenueSearch.module.css";

/**
 * Maximum number of search results to initially display.
 */
const maxSearchResults = 5;

export interface VenueSearchProps {
  /**
   * Foursquare authentication credentials.
   */
  readonly credentials: FoursquareCredentials | undefined;

  /**
   * Callback to invoke when the selected venue changes.
   */
  readonly onVenueChange: (venue: Venue | undefined) => void;
}

/**
 * Fieldset with a search field and a list of selectable search results.
 */
export const VenueSearch = ({ credentials, onVenueChange }: VenueSearchProps) => {
  const [selectedVenue, setSelectedVenue] = React.useState<Venue>();
  const [showAllResults, setShowAllResults] = React.useState<boolean>(false);
  const { value: searchPhrase, setValue: setSearchPhrase, error: searchPhraseError } = useValidatedField({
    required: true
  });
  const { loading, error: searchError, venues } = useVenueSearch({
    credentials,
    near: "London",
    searchPhrase
  });

  // Notify the parent component when the selected venue changes
  React.useEffect(() => {
    onVenueChange(selectedVenue);
  }, [onVenueChange, selectedVenue]);

  React.useEffect(() => {
    // Reset the flag to show all results
    setShowAllResults(false);

    // Clear the selected venue when it no longer appears in the search results
    if (selectedVenue && (!venues || venues.length === 0 || venues.find(venue => venue.id === selectedVenue.id))) {
      setSelectedVenue(undefined);
    }

    // Only "venues" is included here because this only needs to happen when the venues are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues]);

  const venuesToShow = showAllResults ? venues : venues?.slice(0, maxSearchResults);

  return (
    <Fieldset className={styles.VenueSearch} header="Search">
      <Field>
        <FieldLabel htmlFor="searchPhrase">Venue</FieldLabel>
        <ProgressFieldInput
          hasError={!!searchPhraseError}
          id="searchPhrase"
          loading={loading}
          name="searchPhrase"
          onChange={event => setSearchPhrase(event.target.value)}
          type="text"
          value={searchPhrase}
        />
        <FieldError error={searchPhraseError || searchError} />
      </Field>
      {Array.isArray(venues) && venues.length === 0 && (
        <Alert className={styles.NoResults} dismissible={false} type="warning">
          No results
        </Alert>
      )}
      <ol className={styles.VenueSearchResults}>
        {venuesToShow?.map(venue => (
          <li
            key={venue.id}
            className={cx(styles.VenueSearchResultListItem, {
              [styles.VenueSearchResultListItem_selected]: selectedVenue && selectedVenue.id === venue.id
            })}
          >
            <button className={styles.VenueSearchResultButton} onClick={() => setSelectedVenue(venue)}>
              <h3 className={styles.SearchResultName}>{venue.name}</h3>
              <p className={styles.SearchResultAddress}>{venue.location?.formattedAddress.join(", ")}</p>
            </button>
          </li>
        ))}
      </ol>
      {!showAllResults && (venues?.length || 0) > maxSearchResults && (
        <button className={styles.ShowAllResultsButton} onClick={() => setShowAllResults(true)}>
          Show More Results
        </button>
      )}
    </Fieldset>
  );
};
