import { useState, useEffect } from "react";

/**
 * Hook that debounces a value by a number of milliseconds.
 *
 * Sourced from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci.
 */
export function useDebouncedValue(value: string | undefined, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
