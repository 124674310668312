import React from "react";
import { FoursquareCredentials, FoursquareLogin } from "./FoursquareLogin";
import { VenueSearch } from "./VenueSearch";
import { Venue } from "./Venue";
import styles from "./Sidebar.module.css";

interface SidebarProps {
  readonly credentials: FoursquareCredentials | undefined;
  readonly onCredentialsChange: (credentials: FoursquareCredentials | undefined) => void;
  readonly onVenueChange: (venueId: Venue | undefined) => void;
}

const SidebarFooter = () => (
  <div className={styles.SidebarFooter}>
    Built by{" "}
    <a rel="noopener noreferrer" target="_blank" title="Tim Phillips" href="https://www.tim-phillips.com">
      Tim Phillips
    </a>{" "}
    for Ravelin
  </div>
);

/**
 * Sidebar with fieldsets for Foursquare credentials and venue search.
 */
export const Sidebar = ({ credentials, onCredentialsChange, onVenueChange }: SidebarProps) => (
  <div className={styles.Sidebar}>
    <FoursquareLogin onChange={onCredentialsChange} />
    <VenueSearch credentials={credentials} onVenueChange={onVenueChange} />
    <SidebarFooter />
  </div>
);
