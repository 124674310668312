import styles from "./Fieldset.module.css";
import React from "react";
import cx from "classnames";

export const FieldsetHeader = ({ children }: { children: React.ReactNode }) => (
  <h2 className={styles.FieldsetHeader}>{children}</h2>
);

export interface FieldsetProps extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
  readonly header?: string | React.ReactNode | undefined;
}

export const Fieldset = ({ header, children, ...props }: FieldsetProps) => (
  <fieldset {...props} className={cx(styles.Fieldset, props.className)}>
    {header && <FieldsetHeader>{header}</FieldsetHeader>}
    {children}
  </fieldset>
);
