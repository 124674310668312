import cx from "classnames";
import * as d3 from "d3";
import { SimulationNodeDatum, SimulationLinkDatum } from "d3";
import React from "react";
import { MdRestaurantMenu } from "react-icons/md";
import tippy, { Instance } from "tippy.js";
import { VenueWithDepth, SimilarVenues, Venue } from "./Venue";
import styles from "./D3Graph.module.css";

type VenueNode = VenueWithDepth & SimulationNodeDatum;
type VenueLinkNode = SimulationLinkDatum<VenueNode>;

/**
 * Colors to use for nodes in the graph.
 *
 * The index of the array represents the depth of node (the seed node
 * should use nodeColors[0], its neighbours should use newColors[1], etc).
 */
const nodeColors = ["#004d99", "#0080ff", "#66b3ff", "#cce6ff"];

/**
 * Provides a unique element ID for an SVG node in the graph.
 */
const getNodeId = ({ venue }: VenueNode) => `node_${venue.id}`;

/**
 * Initializes the D3 force-directed graph.
 *
 * This function is expected to be invoked *once* when a component is initially
 * mounted. After that, the returned function should be used to provide new
 * data to the graph.
 *
 * For reference:
 *   - https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102
 *   - https://observablehq.com/@pbogden/modifying-a-force-layout
 *
 * @returns An update function that can be used to trigger updating the graph data.
 */
function initializeForceDirectedGraph(container: SVGSVGElement) {
  const svg = d3.select<SVGSVGElement, Venue>(container);

  const simulation = d3
    .forceSimulation()
    .force("charge", d3.forceManyBody().strength(-300))
    .force(
      "link",
      d3
        .forceLink<VenueNode, VenueLinkNode>()
        .id(d => d.venue.id)
        .distance(50)
    )
    .force("x", d3.forceX())
    .force("y", d3.forceY())
    .on("tick", ticked);

  let linkElements = svg
    .append("g")
    .attr("stroke", "#000")
    .attr("stroke-width", 1.5)
    .selectAll<SVGLineElement, VenueLinkNode>("line");
  let nodeElements = svg
    .append("g")
    .attr("stroke", "#fff")
    .attr("stroke-width", 2)
    .selectAll<SVGCircleElement, VenueNode>("circle");

  function ticked() {
    nodeElements.attr("cx", d => d.x || null).attr("cy", d => d.y || null);
    linkElements
      .attr("x1", d => (d.source as VenueNode).x || null)
      .attr("y1", d => (d.source as VenueNode).y || null)
      .attr("x2", d => (d.target as VenueNode).x || null)
      .attr("y2", d => (d.target as VenueNode).y || null);
  }

  function updateSimulation({ venues, venueLinks }: SimilarVenues) {
    const width = container.clientWidth;
    const height = container.clientHeight;
    // "as any" because the typing for "attr" doesn't allow arrays, even though it's a valid parameter
    svg.attr("viewBox", [-width / 2, -height / 2, width, height] as any);

    // Make a shallow copy to protect against mutation, while
    // recycling old nodes to preserve position and velocity.
    const old = new Map(nodeElements.data().map(d => [d.venue.id, d]));
    const nodes: VenueNode[] = venues.map(d => Object.assign(old.get(d.venue.id) || {}, d));
    const links: VenueLinkNode[] = venueLinks.map(d => Object.assign({}, d));

    nodeElements = nodeElements.data(nodes, ({ venue }) => venue.id);
    nodeElements.exit().remove();
    nodeElements = nodeElements
      .enter()
      .append("circle")
      .attr("id", getNodeId)
      .attr("class", styles.D3GraphNode)
      .attr("fill", d => nodeColors[d.distanceFromSeedVenue] ?? nodeColors[nodeColors.length - 1])
      .attr("r", 10)
      .merge(nodeElements)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);

    let tooltips: Instance[] | undefined;
    function handleMouseOver(node: VenueNode) {
      const address = node.venue.location?.formattedAddress?.join(", ");
      tooltips = tippy(`#${getNodeId(node)}`, {
        content: `<div class='venueTooltip'>
            <p class='venueTooltipParagraph'><strong>${node.venue.name}</strong></p>
            <p class='venueTooltipParagraph'>${address}</p>
            </div>`,
        allowHTML: true,
        showOnCreate: true
      });
    }
    function handleMouseOut(_: VenueNode) {
      tooltips?.forEach(tooltip => tooltip.destroy());
    }

    linkElements = linkElements.data(links, ({ source, target }) => [source, target].join("-")).join("line");

    simulation.nodes(nodes);
    (simulation.force("link") as any).links(links);
    simulation.alpha(1).restart();
  }

  return updateSimulation;
}

export interface D3GraphProps {
  /**
   * Venue data to display in the graph.
   */
  readonly similarVenues: SimilarVenues | undefined;
}

/**
 * D3 force-directed graph of similar venues.
 */
export const D3Graph = ({ similarVenues }: D3GraphProps) => {
  const d3Container = React.useRef<SVGSVGElement>(null);
  const updateCallback = React.useRef<(similarVenues: SimilarVenues) => void>();

  // Update the graph whenever the similar venues data is updated
  React.useEffect(() => {
    // Lazily initialize the graph the first time there is data to display
    if (!updateCallback.current && d3Container.current) {
      updateCallback.current = initializeForceDirectedGraph(d3Container.current);
    }
    if (updateCallback.current) {
      updateCallback.current(similarVenues ?? { venues: [], venueLinks: [] });
    }
  }, [similarVenues]);

  const hasVenue = (similarVenues?.venues?.length || 0) > 0;
  return (
    <div className={cx({ [styles.D3GraphContainer_withVenue]: hasVenue })}>
      <MdRestaurantMenu className={cx(styles.RestaurantIcon, { [styles.RestaurantIcon_withNoVenue]: !hasVenue })} />
      <svg className={styles.D3Graph} ref={d3Container} />
    </div>
  );
};
