import React from "react";
import { FoursquareCredentials } from "../components/FoursquareLogin";
import { Venue, SimilarVenues } from "../components/Venue";

/**
 * Data included in a successful response.
 */
export interface SimilarVenuesSuccessResponse {
  response: {
    similarVenues: {
      count: number;
      items: Venue[];
    };
  };
}

/**
 * Data included in a failed response.
 */
export interface SimilarVenuesErrorResponse {
  meta: {
    code: number;
    errorDetail: string;
    errorType: "invalid_auth" | string;
  };
}

/**
 * Loads similar venues for the given list of unvisited venue IDs.
 *
 * @returns An object with the following properties:
 *  - errors: List of errors that occurred while loading similar venues
 *  - newSimilarVenues: Updated similar venues data with the newly loaded venues and links
 *  - newUnvisitedVenueIds: List of new venue IDs that did not already exist in the venue data
 */
async function loadSimilarVenues(
  credentials: FoursquareCredentials,
  maxVenues: number,
  currentSimilarVenues: SimilarVenues,
  unvisitedVenueIds: readonly string[],
  distanceFromSeedVenue: number
) {
  const errors: string[] = [];
  const newVenues = [...currentSimilarVenues.venues];
  const newVenueLinks = [...currentSimilarVenues.venueLinks];
  const newUnvisitedVenueIds: string[] = [];

  // Do one call for each venue and wait until all calls complete before continuing
  await Promise.all(
    unvisitedVenueIds.map(venueId =>
      fetch(
        // TODO: Extract URL to a config file?
        `https://api.foursquare.com/v2/venues/${venueId}/similar?client_id=${credentials.clientId}&client_secret=${credentials.clientSecret}&v=20200226`
      )
        .then(response =>
          processSimilarVenuesResponse(
            response,
            error => errors.push(error),
            similarVenues => {
              for (const venue of similarVenues) {
                const existingVenue = newVenues.find(v => v.venue.id === venue.id);
                if (!existingVenue && newVenues.length >= maxVenues) {
                  continue; // don't add new venues once the limit has been reached
                }
                if (!existingVenue) {
                  newVenues.push({ venue, distanceFromSeedVenue });
                  newUnvisitedVenueIds.push(venue.id);
                }

                const existingLink = newVenueLinks.find(
                  link =>
                    (link.source === venueId && link.target === venue.id) ||
                    (link.source === venue.id && link.target === venueId)
                );
                if (!existingLink) {
                  newVenueLinks.push({ source: venueId, target: venue.id });
                }
              }
            }
          )
        )
        .catch(response => errors.push(response.message ?? "An unexpected error occurred"))
    )
  );

  const newSimilarVenues: SimilarVenues = { venues: newVenues, venueLinks: newVenueLinks };
  return { errors, newSimilarVenues, newUnvisitedVenueIds };
}

/**
 * Parses an API call response and applies updates via the provided callbacks.
 */
async function processSimilarVenuesResponse(
  response: Response,
  setError: (error: string) => void,
  setSimilarVenues: (venues: Venue[]) => void
) {
  const jsonResponse = await response.json();
  if (response.ok) {
    const successResponse: SimilarVenuesSuccessResponse = jsonResponse;
    setSimilarVenues(successResponse.response.similarVenues.items);
  } else {
    const errorResponse: SimilarVenuesErrorResponse = jsonResponse;
    switch (errorResponse.meta.errorType) {
      // See https://developer.foursquare.com/docs/places-api/errors/
      // for a list of known error types; more could be added here to
      // customize error messages.
      case "invalid_auth": {
        setError("Invalid Client ID or Client Secret");
        break;
      }
      default: {
        setError(errorResponse.meta.errorDetail || "An unexpected error occurred loading similar venues");
        break;
      }
    }
  }
}

/**
 * Settings for the "useSimilarVenues" hook.
 */
export interface UseSimilarVenuesSettings {
  readonly credentials: FoursquareCredentials | undefined;
  readonly maxVenues?: number;
  readonly seedVenue: Venue | undefined;
}

/**
 * Hook that loads similar venues from Foursquare when the seed venue changes.
 */
export const useSimilarVenues = ({
  credentials,
  maxVenues = Number.MAX_VALUE,
  seedVenue
}: UseSimilarVenuesSettings) => {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [similarVenues, setSimilarVenues] = React.useState<SimilarVenues>();

  // Start loading a new graph whenever the seed venue changes
  React.useEffect(() => {
    if (!seedVenue) {
      setSimilarVenues(undefined);
      return;
    }
    if (!credentials?.clientId || !credentials?.clientSecret) {
      setError("Missing Client ID or Client Secret");
      setSimilarVenues(undefined);
      return;
    }

    let spaceBarPressed = false;
    function handleKeyUp(event: KeyboardEvent) {
      if (!spaceBarPressed && (event.keyCode === 32 || event.key === " " || event.which === 32)) {
        spaceBarPressed = true;
      }
    }
    document.addEventListener("keyup", handleKeyUp); // disposed in the useEffect's cleanup function

    setLoading(true);
    setError(undefined);

    let isCancelled = false;
    let timeoutHandler: NodeJS.Timeout;
    let unvisitedVenueIds = [seedVenue.id];
    let distanceFromSeedVenue = 0;
    let currentSimilarVenues: SimilarVenues = { venues: [{ venue: seedVenue, distanceFromSeedVenue }], venueLinks: [] };

    // This function will continually load similar venues in a breadth-first search
    // fashion. On each iteration, similar venues will be loaded for all new venues
    // that were found in the previous iteration. Only a single update of the
    // "similarVenues" state will be performed in each iteration, which will include
    // all the new venues and links that were found.
    async function loadSimilarVenuesOnTimer() {
      timeoutHandler = setTimeout(async () => {
        if (
          spaceBarPressed ||
          unvisitedVenueIds.length === 0 ||
          currentSimilarVenues.venues.length >= maxVenues ||
          !credentials
        ) {
          setLoading(false);
          return;
        }

        const { errors, newSimilarVenues, newUnvisitedVenueIds } = await loadSimilarVenues(
          credentials,
          maxVenues,
          currentSimilarVenues,
          unvisitedVenueIds,
          ++distanceFromSeedVenue
        );
        if (isCancelled) {
          // Don't do anything if this effect was cancelled while it was loading
          return;
        }
        if (errors.length > 0) {
          // Arbitrarily showing the first error isn't ideal, but it would
          // also be bad to show a huge list of errors in the UI. Only the
          // first error will be displayed for now.
          setError(errors[0]);
          setLoading(false);
          return;
        } else {
          setError(undefined);
        }
        // End loading if there are no new venues or links
        if (
          newSimilarVenues.venues.length === currentSimilarVenues.venues.length &&
          newSimilarVenues.venueLinks.length === currentSimilarVenues.venueLinks.length
        ) {
          setLoading(false);
          return;
        }
        unvisitedVenueIds = newUnvisitedVenueIds;
        setSimilarVenues(newSimilarVenues);
        currentSimilarVenues = newSimilarVenues;
        loadSimilarVenuesOnTimer();
      }, 1000); // This update frequency could be configurable
    }

    setSimilarVenues(currentSimilarVenues); // Set the seed venue
    loadSimilarVenuesOnTimer(); // Start loading similar venues

    return () => {
      isCancelled = true;
      document.removeEventListener("keyup", handleKeyUp);
      clearTimeout(timeoutHandler);
    };

    // "credentials" isn't included because we only want to perform
    // a search when the selected venue is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedVenue]);

  return { error, loading, similarVenues };
};
