import React from "react";

interface UseValidatedFieldSettings {
  readonly initialValue?: string;
  readonly required?: boolean;
}

/**
 * Hook that validates an input field when updated.
 */
export const useValidatedField = ({ initialValue = "", required = false }: UseValidatedFieldSettings) => {
  const isActive = React.useRef(false);

  const [value, setValue] = React.useState<string>(initialValue);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    if (value) {
      isActive.current = true;
    }
  }, [value]);

  React.useEffect(() => {
    if (!isActive.current) {
      return;
    } else {
      isActive.current = true;
    }
    if (required && !value) {
      setError("Value is required");
    } else if (error) {
      setError("");
    }
  }, [error, required, value]);

  return { error, value, setValue };
};
