import cx from "classnames";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import React from "react";
import styles from "./Field.module.css";

/**
 * Container element for an input field.
 *
 * Child elements typically include "FieldLabel", "FieldInput" and "FieldError".
 */
export const Field = ({ children }: { children: React.ReactNode }) => <div className={styles.Field}>{children}</div>;

export const FieldLabel = (props: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <label {...props} className={cx(styles.FieldLabel, props.className)} />
);

export interface FieldInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly hasError?: boolean;
}

export const FieldInput = ({ hasError = false, ...props }: FieldInputProps) => (
  <input {...props} className={cx(styles.FieldInput, props.className, { [styles.FieldInput_error]: hasError })} />
);

export const FieldError = ({ error }: { error: string | undefined }) =>
  (error && <p className={styles.FieldError}>{error}</p>) || null;

/**
 * Input field that includes a button to toggle showing a plaintext or masked password.
 */
export const PasswordFieldInput = (props: Omit<FieldInputProps, "type">) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className={styles.PasswordFieldInputContainer}>
      <FieldInput {...props} className={styles.PasswordFieldInput} type={showPassword ? "text" : "password"} />
      <button
        className={styles.PasswordFieldInputToggle}
        onClick={() => setShowPassword(!showPassword)}
        title={showPassword ? "Hide Password" : "Show Password"}
      >
        {showPassword ? (
          <AiFillEyeInvisible size={20} area-label="Hide Password" />
        ) : (
          <AiFillEye size={20} area-label="Show Password" />
        )}
      </button>
    </div>
  );
};

/**
 * Input field that conditionally displays a progress spinner.
 */
export const ProgressFieldInput = ({ loading, ...props }: FieldInputProps & { loading: boolean }) => (
  <div className={styles.ProgressFieldInputContainer}>
    <FieldInput {...props} className={cx(styles.ProgressFieldInput, props.className)} />
    {loading && <div className={styles.FieldLoadingIcon} area-label="Loading..." />}
  </div>
);
