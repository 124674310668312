import React from "react";
import { FoursquareCredentials } from "./components/FoursquareLogin";
import { Graph } from "./components/Graph";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Venue } from "./components/Venue";
import styles from "./App.module.css";

/**
 * Root component for the application.
 */
export const App = () => {
  const [credentials, setCredentials] = React.useState<FoursquareCredentials>();
  const [venue, setVenue] = React.useState<Venue>();

  return (
    <div className={styles.App}>
      <Header />
      <Sidebar credentials={credentials} onCredentialsChange={setCredentials} onVenueChange={setVenue} />
      <Graph credentials={credentials} seedVenue={venue} />
    </div>
  );
};
